export default {
  web: "https://web.orangehealth.in",
  globalDomain: "orangehealth.in",
  home: "https://www.orangehealth.in",
  geoMarkHost: "https://geomark-api.orangehealth.in/api",
  authHost: "https://accounts.orangehealth.in/api/v1",
  apiHost: "https://api.orangehealth.in/api/v1",
  apiHostV2: "https://api.orangehealth.in/api/v2",
  apiHostV3: "https://api.orangehealth.in/api/v3",
  apiHostV4: "https://api.orangehealth.in/api/v4",
  cerebroHost: "https://cerebro.orangehealth.in/api/v1",
  omsApiHost: "https://oms-api.orangehealth.in/api",
  scheduler: "https://scheduler-api.orangehealth.in/api/v1",
  bugSnag: "d760c9919b600fa0d5ca10e647c9df90",
  agoraId: "f17c31d8c90f452abeb4d54f29d34645",
  bugSnagWeb: "15cb9c553fc8506bf7f697ea469e61bb",
  vapidKey: {
    vapidKey:
      "BMP7KYKdlnyraNsxipo0979Y4wWIwQJNXg7uoTkpMR2_9zihxz_6bDRRMyHif84qBeSelkTgQu0eKmaa_u4euPk",
  },
  gcpWebProjectId: "orangehealth-gcp-web-project-prod",
  gcpWebProjectKey: "AIzaSyCYbRjuw8z8kPbZfn9IcIjf46sMohdIBNo",
  gcpAppAutocompleteKey: "AIzaSyCMUhEm_chGw60CTcFHBW7ju8V2PdT_0V0",
  mixpanelWebAppProjectToken: "ca45550d8f739def75ddbe29f895ed81",
  mixpanelCustomerAppProjectToken: "ef22d952f3e35c630ca5f0b9ecfef457",
  mixpanelDoctorAppProjectToken: "ba69d058f46b35cf8030400ab4eb8f6d",
  fbPixelKey: "1349005202168880",
  gaTagMangerId: "GTM-PJQPHCR",
  appsFlyerDevKey: "9ouE4xoaffU2SVdj8yDpJc",
  appsFlyerAppId: "id1534689588",
  customerEmedicBridge: {
    enabled: true,
    bridgeNumber: "+911141171360",
  },
  cleverTapId: "844-869-R66Z",
  smartLookAppProjectToken: "7badac57c2c0b09f5df0877173194c2ee5e5b44e",
  appName: "OH-Patient",
  appVersion: "4.1.4",
  smartLookKey: "57e2e493d1f190a9157ba66e4f6d044d1e88b14d",
  hclPartnerCode: "zfz9C07jlELT0UXu",
};
